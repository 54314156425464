import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const Brands = props => {
    const authors = useBlogAuthors()
    return (
        <Layout {...props}>
            <Seo title='Marken' />
            <Divider />
            <Stack effectProps={{ effect: 'fadeInDown' }}>
                <PageTitle
                    header='Unsere Marken'
                    subheader='Primula Medical Travel bietet unterschiedliche Typen von Services für medizinische Behandlungen im Urlaub an. Für die einfache Unterscheidung veröffentlichen wir die Inhalte auf unserer Webseite unter verschienden Marken, wie z.B. Primula Dental und Primula Hair.'
                />
            </Stack>
            <Stack>
                <Main>
                    {authors.map(author => (
                        <>
                            <Divider />
                            <AuthorExpanded author={author} withLink />
                        </>
                    ))}
                </Main>
            </Stack>
        </Layout>
    )
}
export default Brands;